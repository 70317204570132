import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.primaryBlue};
    font-size: ${({ theme }) => theme.font.size.heading};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
`;

export const ContentWrapper = styled.div`
    display: grid;
`;

export const StyledImage = styled(GatsbyImage)`
`;